$body-color: #F8F4EA;
$dark-color: #605B55;
$medium-color: #A17344;
$clear-color: #D1BFAF;
$gold-color: #F3B15A;
$font-titles: 'Parisienne';
$font-caligraphy: 'Parisienne';
$font-normal: 'Montserrat';

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;600&family=Montserrat:wght@300;400;700&family=Parisienne&display=swap');
//font-family: 'Caveat', cursive;
//font-family: 'Montserrat', sans-serif;
//font-family: 'Parisienne', cursive;

// We define `null` as the default value for each argument
@mixin text($size: null, $lineHeight: null, $weight: null) {
    @if $size != null {
      font-size: $size;
    }
  
    @if $lineHeight != null {
      line-height: $lineHeight;
    }
  
    @if $weight != null {
      font-weight: $weight;
    }
  }
  
  // Let's name this "min-width" and take a single argument we can
  // use to define the viewport width in a media query.
  @mixin min-width($threshold) {
    // We're calling another function (scut-rem) to convert pixels to rem units.
    // We'll cover that in the next section.
    @media screen and (min-width: scut-rem($threshold)) {
      @content;
    }
  }
  @mixin max-width($threshold) {
    // We're calling another function (scut-rem) to convert pixels to rem units.
    // We'll cover that in the next section.
    @media screen and (max-width: scut-rem($threshold)) {
      @content;
    }
  }